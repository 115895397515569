import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, empty } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AccountService } from '../services/account.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import * as jwt_decode from "jwt-decode";

@Injectable()
export class AuthCheckInterceptor implements HttpInterceptor {

  constructor(private _router: Router, private accountService: AccountService) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes("/Auth")) {
      return next.handle(req);
    }

    let authToken = localStorage.getItem('token');
    if (authToken) {
      return next.handle(
        req.clone({
          headers: req.headers.append('Authorization', 'Bearer ' + authToken)
        })
      ).pipe(tap((response: HttpResponse<any>) => {
        var res = this.tokenExpired(authToken);
        if (res == true) {
          this.accountService.logout();
          return response;        
        }
        if (response instanceof HttpResponse) {
          if (response.status == 401) {
            this.accountService.logout();
            return response;
          }
        }
      }));
    }
    this._router.navigate(['/login']);
    return empty();
  }

  private tokenExpired(token: any) {
    const expiry : any = jwt_decode(token).exp;
    let now = Math.floor((new Date).getTime() / 1000);
    return now >= expiry;
  }
}