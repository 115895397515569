import { CookieService } from 'ngx-cookie-service';

import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, pipe, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as jwt_decode from "jwt-decode";

@Injectable({
  providedIn:'root'
})
export class AccountService {

  public user: any = {};
  private baseUrl = `${environment.apiUrl}`;
  private jwtToken;
  public get userName():string {
    return localStorage.getItem('userName');
  }
  constructor(private router: Router, private route: ActivatedRoute, private cookieService: CookieService,
    private http: HttpClient) {
  }

  login(username: string, password: string) {
    var loginData = {
      username: username,
      password: password
    };
    this.doAuthentication(loginData);

  }

  authFailed = false;

  public get isLoggedIn(): boolean {
    return !!localStorage.getItem('token');
  }

  authRequest(loginData): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/Auth/Authenticate", loginData)
      .pipe(
        catchError(this.handleError)
      );
  }

  doAuthentication(loginData) {
    this.authRequest(loginData).subscribe(tokenJson => {
      this.jwtToken = tokenJson.token;
      if (tokenJson.token != undefined) {
        let tokenInfo = jwt_decode(tokenJson.token);
        localStorage.setItem('token', this.jwtToken);
        localStorage.setItem('userName', tokenInfo.unique_name);
        localStorage.setItem('exp', tokenInfo.exp);

        this.user.username = tokenInfo.unique_name;
        this.router.navigate(['/']);
      }

    })


  }

  logout() {
    this.user = {};
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.removeItem('exp');
    this.router.navigateByUrl('/auth/login');
  }

  errorMessage: any;
  private handleError(err: HttpErrorResponse) {
    if (err.error instanceof ErrorEvent) {
      this.errorMessage = `An error occurred: ${err.error.message}`;
    } else if (err.status == 401) {
      this.errorMessage = 'Authorization Failed, Username or password Incorrect';
    }
    return of(this.errorMessage);
  }
}