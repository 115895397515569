import { Injectable } from "@angular/core";
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { of, Observable } from 'rxjs';
import { AccountService } from '../services/account.service';

@Injectable({
    providedIn:'root'
})
export class AuthGuard implements CanActivate {
    constructor(private accountService: AccountService, private router: Router) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.accountService.isLoggedIn) {
            this.router.navigate(['/auth/login']);
            return of(false)
        } else {
            return of(true)
        }
    }
}